const faqs = [
  {
    question: "Requisitos para viajar a Chile desde Argentina",
    answer: (
      <div>
        <p>- Pasaporte o cédula de identidad vigente: Los ciudadanos argentinos pueden ingresar a Chile con su pasaporte o cédula de identidad / DNI vigente. Es importante que estos documentos se encuentren en buen estado y con la fecha de vencimiento vigente.</p><br/>
        <p>- Formulario de control de ingreso con productos vegetales o animales: Antes de ingresar a Chile, toda persona que ingrese al país, chilena o extranjera, deben completar el formulario de control de ingreso con productos vegetales o animales, el hecho de portar o traer consigo, en sus vestimentas, en su equipaje o de algún modo en el medio de transporte en el que se traslada, uno o más de los productos a que se refiere el párrafo anterior. Este formulario se encuentra disponible en la página web Ingreso a Chile. Este formulario debe ser impreso y presentado en el control migratorio al momento de ingresar al país. Si no lo hiciste, podrás llenar el formulario utilizando la red WiFi gratuita del Estado, mediante un código QR.</p><br/>
        <p>- Certificado de vacunación COVID-19: Desde el 1 de noviembre de 2021, los ciudadanos argentinos deben presentar un certificado de vacunación COVID-19 válido para ingresar a Chile. Este certificado debe estar en español, inglés, francés o portugués y debe contener información sobre la vacuna recibida, el fabricante, las dosis aplicadas y la fecha de la última dosis.</p><br/>
        <p>- Seguro de salud: Todos los ciudadanos o residentes argentinos que ingresen a Chile deben contar con un seguro de salud que cubra gastos relacionados con el COVID-19 durante su estadía en el país. Este seguro debe ser válido para toda la duración de la estadía en Chile y debe cubrir al menos USD 30.000 en gastos médicos.</p>
      </div>
    ),
  },
  {
    question: "¿Puedo viajar a Chile como turista con mi DNI argentino?",
    answer: (
      <div>
        <p>- Los turistas extranjeros de cualquier nacionalidad que residan permanentemente en Argentina podrán ingresar a Chile, siempre y cuando posean un Documento Nacional de Identidad (DNI) de Residencia Permanente válido. 
 </p>  <br/> 
 <p>- Se acepta solamente el DNI original como documento de viaje, y no se aceptarán resoluciones de otorgamiento de residencia, notificaciones u otros. Es importante destacar que el DNI temporario o la Residencia Precaria no son documentos válidos para viajar a Chile. Si la persona no cuenta con el estatus de residente permanente, deberá seguir el proceso correspondiente para solicitar la visa adecuada, según su nacionalidad.</p>

      </div>
    ),
  },
 
];

export default faqs;
